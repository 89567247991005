<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <!--begin::Post-->
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <!--begin::Container-->
      <div id="kt_content_container" class="container">
        <!--begin::Basic info-->
        <div class="card mb-5 mb-xl-10">
          <!--begin::Card header-->
          <div
            class="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <!--begin::Card title-->
            <div class="card-title m-0">
              <h3 class="fw-bolder m-0">{{ product.name }}</h3>
            </div>
            <!--end::Card title-->
          </div>
          <!--begin::Card header-->

          <!--begin::Content-->
          <div id="kt_account_profile_details" class="collapse show">
            <!--begin::latest instagram-->
            <div class="card-body border-top p-9">
              <div class="mb-8" v-if="product_image.length > 0">
                <!--begin::Row-->
                <div class="row g-10">
                  <!--begin::Col-->
                  <div
                    class="col-4"
                    v-for="(images, key) in product_image"
                    :key="key"
                  >
                    <!--begin::Overlay-->

                    <!--begin::Image-->
                    <a @click="show(key)" class="d-block">
                      <!--begin::Image-->
                      <div
                        class="
                          overlay-wrapper
                          bgi-no-repeat bgi-position-center bgi-size-cover
                          card-rounded
                          min-h-175px
                        "
                        style="cursor: pointer"
                        :style="{ backgroundImage: 'url(' + images.src + ')' }"
                      ></div>
                      <!--end::Image-->
                    </a>
                    <button
                      type="button"
                      @click="deleteImage(images.id, id)"
                      class="btn btn-outline-danger mt-1 w-100"
                    >
                      ลบ
                    </button>

                    <!--end::Image-->
                  </div>

                  <vue-easy-lightbox
                    scrollDisabled
                    escDisabled
                    moveDisabled
                    :visible="visible"
                    :imgs="product_image"
                    :index="index"
                    @hide="handleHide"
                  ></vue-easy-lightbox>

                  <!--end::Col-->
                </div>
                <!--begin::Row-->
              </div>
              <!--end::latest instagram-->
            </div>

            <!--begin::Form-->
            <Form
              v-if="product"
              @submit="onSubmitProduct"
              :validation-schema="productValidator"
              id="kt_account_profile_details_form"
              class="form"
              novalidate="novalidate"
            >
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Input group-->

                <BaseInput
                  name="product_id"
                  type="hidden"
                  v-model="product.id"
                />
                <BaseImage
                  label="รูปภาพ"
                  required="required"
                  name="image"
                  :aspectRatio="1 / 1"
                  :height="800"
                  :width="800"
                  ref="emptyimage"
                />
              </div>
              <!--end::Card body-->

              <!--begin::Actions-->
              <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  ref="submitButton"
                  class="btn btn-primary"
                >
                  <span class="indicator-label"> ยืนยัน </span>
                  <span class="indicator-progress">
                    กรุณารอสักครู่ ...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
              <!--end::Actions-->
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Basic info-->
      </div>
    </div>
  </div>
</template>

<script>
import BaseImage from "@/components/modals/BaseImage.vue";
import BaseInput from "@/components/modals/BaseInput.vue";
import { defineComponent, onMounted, ref } from "vue";
import { Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import useSweetalert from "@/core/helpers/sweetalert2";
import useProduct from "@/core/services/api/travel/market_sun/product";
import { checkPage } from "@/core/helpers/checkpage";
import "vue-advanced-cropper/dist/style.css";
import { useRouter } from "vue-router";
import VueEasyLightbox from "vue-easy-lightbox";
export default defineComponent({
  name: "market_sun-product-edit",
  components: {
    Form,
    BaseImage,
    BaseInput,
    VueEasyLightbox,
  },
  props: ["id"],
  setup(props) {
    const router = useRouter();
    const { getProductImage, updateProductImage, destroyProductImage } =
      useProduct();
    const { Sconfirm, SToast, SconfirmNc } = useSweetalert();
    const submitButton = ref(null);
    const product = ref("");
    const product_image = ref("");
    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);
    const visible = ref(false);
    const index = ref(0);
    const emptyimage = ref(null);
    const productValidator = Yup.object().shape({
      image: Yup.string().required("กรุณาเลือก รูปภาพ"),
    });
    function handleHide() {
      visible.value = false;
    }
    function show(key) {
      index.value = key;
      visible.value = true;
    }

    const deleteImage = (id, di) => {
      Sconfirm("ยืนยัน การลบรูปภาพ", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;
          await destroyProductImage(id);
          await getProductImage(di)
            .then((response) => {
              product.value = response.data.data.product;
              product_image.value = response.data.data.product_image;
              isLoading.value = false;
            })
            .catch(() => {
              SconfirmNc(
                "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
                "error",
                "ลองอีกครั้ง !"
              );
              isLoading.value = false;
            });
          isLoading.value = false;
          SToast("success", "ลบ สินค้า สำเร็จ");
        }
      });
    };

    const onSubmitProduct = (values) => {
      Sconfirm("ยืนยัน การเพิ่มรูปภาพ", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;
          if (submitButton.value) {
            // Activate indicator
            submitButton.value.setAttribute("data-kt-indicator", "on");
            submitButton.value.setAttribute("disabled", "disabled");
          }
          await updateProductImage(values)
            .then((res) => {
              emptyimage.value.deleteImage();
              getProductImage(res.data.id)
                .then((response) => {
                  product.value = response.data.data.product;
                  product_image.value = response.data.data.product_image;
                  isLoading.value = false;
                })
                .catch(() => {
                  SconfirmNc(
                    "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
                    "error",
                    "ลองอีกครั้ง !"
                  );
                });
              submitButton.value?.removeAttribute("data-kt-indicator");
              submitButton.value?.removeAttribute("disabled");
              SToast("success", "เพิ่ม รูปภาพ สำเร็จ");
            })
            .catch(() => {
              submitButton.value?.removeAttribute("data-kt-indicator");
              submitButton.value?.removeAttribute("disabled");
              SconfirmNc(
                "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
                "error",
                "ลองอีกครั้ง !"
              );
            });
        }
      });
    };

    onMounted(() => {
      if (!props.id) {
        return router.push({ name: "apps.travels.market_sun.product.index" });
      }
      getProductImage(props.id)
        .then((response) => {
          product.value = response.data.data.product;
          product_image.value = response.data.data.product_image;
          isLoading.value = false;
        })
        .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
        });

      checkPage("market_sun-product-type-create");
      setCurrentPageBreadcrumbs("จัดการรูปภาพ", [
        "ส่งเสริมการท่องเที่ยว",
        "ตลาดนัดวันอาทิตย์",
      ]);
    });

    return {
      product,
      productValidator,
      submitButton,
      onSubmitProduct,
      isLoading,
      color,
      product_image,
      visible,
      handleHide,
      index,
      show,
      emptyimage,
      deleteImage,
    };
  },
});
</script>
<style scoped>
</style>
